<template>
  <!-- The container for the Google Map -->
  <div ref="map" style="width: 100%; height: 100%;"></div>
</template>

<script>
import axios from 'axios';

// Shared promise variable to load the Google Maps API only once.
let google_maps_promise = null;

/**
 * Dynamically loads the Google Maps API.
 * @param {string} api_key - Your Google Maps API key.
 * @returns {Promise} - Resolves with the google object.
 */
function load_google_maps(api_key) {
  if (google_maps_promise) {
    return google_maps_promise;
  }
  google_maps_promise = new Promise((resolve, reject) => {
    // If already loaded, resolve immediately.
    if (window.google && window.google.maps) {
      resolve(window.google);
      return;
    }
    window.init_google_maps = function() {
      console.log('got callback');
      resolve(window.google);
    };
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${api_key}&callback=init_google_maps`;
    script.async = true;
    script.defer = true;
    script.onerror = reject;
    document.head.appendChild(script);
  });
  return google_maps_promise;
}

export default {
  name: 'GoogleMap',
  props: {
    // Your Google Maps API key.
    api_key: {
      type: String,
      required: true
    },
    // Map configuration options.
    map_options: {
      type: Object,
      default: () => ({
        center: { lat: 0, lng: 0 },
        zoom: 8
      })
    },
    // The map id from your backend (e.g., the id of the map record).
    map_id: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      map: null,
      markers: [] // local storage for marker objects
    };
  },
  mounted() {
    
    load_google_maps(this.api_key)
      .then(() => {
        this.initialize_map();
      })
      .catch(error => {
        console.error('Error loading Google Maps API:', error);
      });
  },
  methods: {
    /**
     * Initialize the map and load existing markers.
     */
    initialize_map() {
      this.map = new google.maps.Map(this.$refs.map, this.map_options);
      // Load markers from the backend.
      this.load_markers();

      // Listen for clicks on the map to add new markers.
      this.map.addListener('click', this.handle_map_click);
    },

    /**
     * Fetch markers from the backend and add them to the map.
     */
    load_markers() {
      axios
        .get(`/maps/${this.map_id}/markers`)
        .then(response => {
          const markers_from_backend = response.data;
          markers_from_backend.forEach(marker_data => {
            // Add each marker to the map (do not open the info window on load)
            this.add_marker_to_map(marker_data, false);
          });
        })
        .catch(error => {
          console.error('Error loading markers from backend:', error);
        });
    },

    /**
     * Handler for map click events.
     * @param {object} event - The click event.
     */
    handle_map_click(event) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      const info = window.prompt('Enter information for this marker:');
      if (info === null) return;

      // Create a marker data object. You can extend this with additional fields as needed.
      const marker_data = {
        lat: lat.toString(),
        lng: lng.toString(),
        name: info,
        // For example, you might want the user to choose a type; here we default to UNKNOWN_RUIN.
        type: "UNKNOWN_RUIN"
      };

      // POST the marker data to the backend.
      axios
        .post(`/maps/${this.map_id}/markers`, marker_data)
        .then(response => {
          // On success, add the marker to the map.
          const saved_marker_data = response.data;
          this.add_marker_to_map(saved_marker_data, true);
        })
        .catch(error => {
          console.error('Error saving marker to backend:', error);
        });
    },

    /**
     * Adds a marker to the map and sets up an info window.
     * @param {object} marker_data - The marker data returned from the backend.
     * @param {boolean} show_info - Whether to immediately open the info window.
     */
    add_marker_to_map(marker_data, show_info) {
      const position = {
        lat: parseFloat(marker_data.lat),
        lng: parseFloat(marker_data.lng)
      };
      const marker = new google.maps.Marker({
        position,
        map: this.map,
        title: marker_data.name
      });

      const info_window = new google.maps.InfoWindow({
        content: `<div>${marker_data.name}</div>`
      });

      marker.addListener('click', () => {
        info_window.open(this.map, marker);
      });

      if (show_info) {
        info_window.open(this.map, marker);
      }

      // Store the marker locally if you need to reference or update it later.
      this.markers.push({ marker, info: marker_data.name });
    }
  }
};
</script>

<style scoped>
/* Ensure the map container fills its parent element */
div[ref="map"] {
  width: 100%;
  height: 100%;
}
</style>
