<template>
  <div style="height: 100%; width: 100%;">
    <GoogleMap
      api_key="AIzaSyA6TAPuhK96gq9vMpeF27m6icCgqkqn1rM"
      :map_options="{ center: { lat: 37.7749, lng: -122.4194 }, zoom: 8 }"
      :map_id="1"
    />
  </div>
</template>

<style lang="scss" scoped>

:deep .v-application--wrap {
  background: white !important;
}

:deep body {
  background: white !important;
}

</style>

<script>
import messageParent from '@/core/services/messageParent';
import dayjs from 'dayjs';
import axios from 'axios';
import GoogleMap from '@/view/components/maps/GoogleMap.vue';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'ArcheologyMap',
  mixins: [ toasts ],

  components: {
    GoogleMap
  },

  data() {
    return {
      
    };
  },
  mounted() {
    setInterval(function(){
      messageParent(JSON.stringify({ height: document.body.scrollHeight }));
    }, 300);
  },
  watch: {
    
  },
  methods: {

  }
};
</script>
